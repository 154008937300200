<template>
  <div class="mt-4">
    <div class="d-flex mt-4 align-items-center justify-content-between">
      <h1 class="page-title">
        <b-icon icon="building" />
        {{ $t("orgs.title") }}
      </h1>
      <router-link to="/admin/organizations/new">
        <b-button variant="primary" data-cy="new-org-button" pill>
          <b-icon-person-plus class="mr-2" />
          {{ $t("orgs.newOrg") }}
        </b-button>
      </router-link>
    </div>

    <b-card border-variant="light" class="mt-4 shadow-sm card-rounded">
      <b-form @submit.prevent="search">
        <b-form-group>
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              autocomplete="off"
              data-cy="search-input"
              :placeholder="$t('typeToSearch')"
              type="search"
            />

            <b-input-group-append>
              <b-button data-cy="user-clear-search-button" @click="clearFilter">
                <b-icon-x />
              </b-button>

              <b-button
                variant="primary"
                data-cy="user-search-button"
                type="submit"
              >
                {{ $t("search") }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-form>

      <div class="overflow-auto">
        <b-table
          :busy="loading"
          :current-page="currentPage"
          :fields="fields"
          :head-variant="'light'"
          :items="tenants"
          :per-page="0"
          class="mb-0"
          data-cy="organizations-table"
          fixed
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle mr-2" />
              <strong>{{ $t("loading") }}</strong>
            </div>
          </template>

          <template #cell(name)="data">
            <router-link
              :data-cy="`manage-organization-${data.item.name.replace(
                ' ',
                '-'
              )}`"
              :to="'/admin/organization/' + data.item.id"
            >
              {{ data.item.displayName }}
            </router-link>
          </template>

          <template #cell(userCount)="data">
            <span data-cy="org-user-count"> {{ data.item.userCount }} </span>
          </template>

          <template #cell(empty)="data">
            <div class="d-flex justify-content-end">
              <b-button
                :data-cy="`delete-organization-${data.item.name.replace(
                  ' ',
                  '-'
                )}`"
                :disabled="data.item.userCount > 0"
                class="ml-2"
                pill
                size="sm"
                variant="outline-danger"
                @click="deleteModal(data.item.id)"
              >
                <b-icon-trash />
              </b-button>
            </div>
          </template>
        </b-table>

        <b-pagination
          v-if="total > pageSize"
          v-model="currentPage"
          :per-page="pageSize"
          :total-rows="total"
          align="right"
          class="mb-0 mt-3"
          first-text="First"
          last-text="Last"
          next-text="Next"
          prev-text="Prev"
        />
      </div>
      <b-card-text
        v-if="tenants.length === 0 && !loading"
        class="text-center my-4"
      >
        {{ $t("orgs.emptyTable") }}
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "OrganizationsAdmin",
  data() {
    return {
      tenants: [],
      pageSize: 10,
      total: null,
      currentPage: 1,
      fields: [
        { key: "name", label: "Name" },
        { key: "userCount", label: "Users" },
        { key: "empty", label: "" },
      ],
      loading: true,
      filter: null,
    };
  },
  mounted() {
    this.tenantsProvider();
  },
  methods: {
    deleteModal(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure that you want to delete this Organization?",
          {
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Yes",
            cancelTitle: "No",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) this.deleteTenant(id);
        })
        .catch((err) => {
          if (err.response) console.log(err.response);
          // An error occurred
        });
    },
    // delete a tenant
    async deleteTenant(id) {
      try {
        await this.$http.delete(
          `${this.$cfg.BASE_IDENTITY_VERSION_URL}/tenants/${id}`
        );
        await this.tenantsProvider();
      } catch (err) {
        if (err.response) console.log(err.response);
      }
    },

    // get tenants
    async tenantsProvider() {
      this.loading = true;
      try {
        const {
          data: { content, page },
        } = await this.$http.get(
          this.$cfg.BASE_IDENTITY_VERSION_URL + "/tenants",
          {
            params: {
              page: this.currentPage - 1,
              pageSize: this.pageSize,
              search: this.filter,
            },
          }
        );

        this.total = page.totalElements;
        this.tenants = content;
      } catch (err) {
        if (err.response) console.log(err.response);
      } finally {
        this.loading = false;
      }
    },
    search() {
      this.loading = true;
      this.currentPage = 1;
      this.tenantsProvider();
    },

    clearFilter() {
      this.filter = null;
      this.currentPage = 1;
      this.tenantsProvider();
    },
  },
  watch: {
    currentPage: {
      handler: function () {
        this.tenantsProvider();
      },
    },
  },
};
</script>
